import React from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";


import { useQuery, gql } from '@apollo/client';
import {GetMyProfileQuery, UserType} from "gql/graphql";
import {Loader} from "components/Loader";

const GET_MY_PROFILE = gql`
  query GetMyProfile {
    me {
        username
        firstName
        lastName
        
        pricingTableSecret
    }
  }
`;

function MainHome() {
  // const {userId} = useAuth();
  // const {data, isError, error} = api.user.getUserinfo()

  // console.log("DATA", data, userId, isError, error);

  const { loading, error, data } = useQuery<GetMyProfileQuery>(GET_MY_PROFILE);

  if (loading) return <Loader />;

  // data.me.username

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/*<div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">*/}
      {/*  /!* SECTION HERO *!/*/}
      {/*  <SectionHero*/}
      {/*    title={"Welcome to PuhkuseTark!"}*/}
      {/*    text={"Accompanying us, you have a trip full of experiences. With Chisfis,\n" +*/}
      {/*      "            booking accommodation, resort villas, hotels"}*/}
      {/*    imagePng={imagePng}*/}
      {/*    className="pt-10 lg:pt-16 lg:pb-16" />*/}
      {/*</div>*/}

      {/*<div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">*/}
      {/*  <StripePricingTable tableId="prctbl_1QND11LtxROrGQnS9Pakyryd" sessionSecret={data.me?.pricingTableSecret} />*/}
      {/*</div>*/}
    </div>
  );
}

export default MainHome;
