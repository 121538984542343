import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import Page404 from "containers/Page404/Page404";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import SiteHeader from "containers/SiteHeader";
import MainHome from "./pages/MainHome/MainHome";
import TermsOfService from "./pages/Legal/TermsOfService";
import Privacy from "./pages/Legal/Privacy";
import {Helmet} from "react-helmet-async";
import {PathName} from "../demo/types";
import Header from "../../components/Header/Header";


function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <BrowserRouter>
        <ScrollToTop/>
        <Helmet>
          <title>PuhkuseTark</title>
        </Helmet>
        <Header showNav={false} className="shadow-sm dark:border-b dark:border-neutral-700" navType="MainNav1" />
        <Routes>
          <Route path="/" element={<MainHome/>}/>
          <Route path="/me" element={<AuthorPage/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/tos" element={<TermsOfService/>}/>
          <Route path="*" element={<Page404/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
