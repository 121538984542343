import React from "react";

import MainApp from "apps/main";
import DemoApp from "apps/demo";
import TenantApp from "apps/tenant";

const apps = {
  main: MainApp,
  demo: DemoApp,
  tenant: TenantApp,
}

const targetApp = process.env.REACT_APP_BUILD_APP;
const CurrentApp = apps[targetApp];


function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <CurrentApp />
    </div>
  );
}

export default App;
