import React from "react";
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import Footer from "shared/Footer/Footer";
import {TenantPage} from "./pages";
import {TenantData} from "./types";

// @ts-ignore
const tenantData: TenantData|null = window.TENANT_DATA || null;

function App() {
  if (!tenantData) return null;

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <BrowserRouter>
        <ScrollToTop/>
        <TenantPage tenant={tenantData} />
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
