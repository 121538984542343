import {ApolloClient, InMemoryCache, createHttpLink, from} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {useAuth} from '@clerk/clerk-react';
import {PropsWithChildren, useMemo} from "react";
import {ApolloProvider} from '@apollo/client';


const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});


export const ApolloProviderWrapper = ({ children }: PropsWithChildren) => {
  const { getToken } = useAuth()

  const client = useMemo(() => {
    const authMiddleware = setContext(async (operation, { headers }) => {
      const token = await getToken()

      return {
        headers: {
          ...headers,
          ...(token && {authorization: `Bearer ${token}`}),
        },
      }
    })

    return new ApolloClient({
      link: from([authMiddleware, httpLink]),
      cache: new InMemoryCache(),
    })
  }, [getToken])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
