import {Route, Routes} from "react-router-dom";
import PlaceHome from "./PlaceHome/PlaceHome";
import Page404 from "containers/Page404/Page404";
import React from "react";
import {Loader} from "components/Loader";


import { useQuery, gql } from '@apollo/client';

const GET_TENANT = gql`
  query GetTenant($slug: String!) {
    tenant(slug:$slug) {
      id
      name
    }
  }
`;

export function TenantPage({tenant}) {
  // const { loading, error, data } = useQuery(GET_TENANT, {
  //   variables: {slug: tenantID},
  // });
  //
  //
  // if (loading) {
  //   return <Loader />
  // }
  //
  // if (!data.tenant) {
  //   return <Page404/>
  // }
  //
  // if (error) {
  //   return <>Error {error.name}: {error.message}</>
  // }

  return <>
    <Routes>
      <Route path="/" element={<PlaceHome placeName={`Tenant "${tenant.name}"<${tenant.slug}>`}/>}/>
      <Route path="*" element={<Page404/>}/>
    </Routes>
  </>;
}